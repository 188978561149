.container {
  color: #080708;
  margin-top: 30px;
  padding: 0px 50px;
  max-width: 880px;
}

.title {
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
}

.subtitle {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 5px 0 20px 0;
  padding: 0;
}

@media (max-width: 900px) {
  .container {
    padding: 0 20px;
    margin: auto;
    margin: 20px 0;
  }

  .title {
    font-size: 38px;
  }

  .subtitle {
    font-size: 20px;
  }
}
