.container {
  display: flex;
  align-items: center;
  color: #080708;
  margin-top: 30px;
  padding: 0px 50px;
}

.introText {
  flex: 1.5;
}

.title {
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
}

.subtitle {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 5px 0 20px 0;
  padding: 0;
}

.introBtn {
  padding: 15px 70px;
  font-size: 24px;
  border-radius: 10px;
  border: none;
  color: #f0f1f4;
  box-shadow: 0px 10px 18px 0 rgba(0,0,0,0.16);
}

.createBtn {
  background-color: #fad00d;
}

.exploreBtn {
  background-color: #d9298a;
  margin-left: 25px;
}

.imgContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-left: 80px;
}

.logoImg {
  width: 400px;
  height: 300px;
  object-fit: scale-down;
  margin: auto;
}

@media (max-width: 900px) {
  .imgContainer {
    display: none;
  }

  .container {
    padding: 0 20px;
    margin: auto;
    margin: 20px 0;
  }

  .title {
    font-size: 38px;
  }

  .subtitle {
    font-size: 20px;
  }
}
