.wrapper {
  height: 100%;
}

.container {
  color: #080708;
  margin-top: 30px;
  padding: 0px 25px;
  margin-bottom: 50px;
}

.instructionsTitleContainer {
  padding: 0 50px;
  display: flex;
  align-items: center;
}

.instructionsTitleContainer:hover {
  transform: translateY(-2px);
  cursor: pointer;
  transition: 0.4s;
}

.instructionsTitle {
  padding: 0 10px;
  font-size: 38px;
  font-weight: 500;
  letter-spacing: 2px;
  text-decoration: underline;
  margin: 0;
}

.createContainer {
  display: flex;
  margin-top: 25px;
  width: 100%;
  justify-content: space-between;
}

.instructions {
  width: 460px;
  height: 540px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* for custom scrollbar */
.instructions::-webkit-scrollbar {
  width: 11px;
}
.instructions {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.instructions::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.instructions::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.imgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.imgBoxContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.imgBox {
  border: 1px solid black;
  width: 180px;
  height: 120px;
  margin: 3px;
}

.imgBox:hover {
  cursor: pointer;
}

.clickedImgBox {
  z-index: 1;
  opacity: 0.6;
}

.clickedImgBoxText {
  z-index: 2;
  background-color: #080708;
  color: #fff;
  border-radius: 10px;
  padding: 5px;
  position: absolute;
  left: 45%;
}

.canvas {
  align-self: flex-end;
  flex: 2;
  background-color: #fff;
  width: 810px;
  height: 540px;
  margin: auto;
  box-shadow: 0px 10px 18px 0 rgba(0,0,0,0.16);
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas img {
  position: absolute;
  width: 810px;
  height: 540px;
  object-fit: scale-down;
}

.submitBtn {
  width: 810px;
  padding: 16px 70px;
  font-size: 24px;
  border-radius: 10px;
  border: none;
  color: #f0f1f4;
  color: #080708;
  box-shadow: 0px 10px 18px 0 rgba(0,0,0,0.16);
  background-color: rgba(250, 208, 13, 0.9);
  letter-spacing: 1px;
  margin-top: 20px;
}

.submitBtn:hover {
  transform: translateY(-3px);
  box-shadow: 0px 10px 18px 0 rgba(0,0,0,0.25);
  cursor: pointer;
  transition: 0.4s;
}

@media (max-width: 1200px) {
  .createContainer {
    flex-direction: column;
  }

  .instructionsTitleContainer {
    padding: 0 0px;
    display: flex;
    align-items: center;
  }

  .instructionsTitle {
    font-size: 38px;
  }

  .instructions {
    width: 100%;
  }

  .createdContainer {
    width: 100%;
    text-align: center;
  }

  .canvas {
    width: 300px;
    height: 200px;
    margin-top: 20px;
  }

  .canvas img {
    width: 300px;
    height: 200px;
  }

  .submitBtn {
    font-size: 24px;
    width: 300px;
    padding: 5px 30px;
  }
}
