.container {
  color: #fff;
  background-color: #d9298a;
  padding: 20px 50px;
}

.container p {
  margin: 5px 0;
  padding: 0;
  letter-spacing: 1px;
}

.link {
  text-decoration: none;
  color: #fad00d;
  font-weight: 600;
  border-bottom: 1px solid #fad00d;
  display: inline-block;
}

.link:hover {
  transform: translateY(-2px);
  cursor: pointer;
  transition: 0.4s;
}

@media (max-width: 900px) {
  .container {
    padding: 20px;
  }
}
