.container {
  z-index: 99;
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
}

.background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.modal {
  background: #fff;
  color: #181d27;
  z-index: 100;
  border-radius: 10px;
  padding: 25px;
  width: 500px;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.modalHeader h2, .modalHeader i {
  margin: 0;
  padding: 0;
}

.modalHeader i {
  cursor: pointer;
  font-size: 18px;
}

.modalInfo {
  margin: 15px 0 5px 0;
}

.modalInfo li {
  margin-top: 20px;
}

.link {
  color: #d9298a;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.inputField {
  margin-top: 5px;
  color: #080708;
  font-size: 16px;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid #080708;
}

.inputField:focus {
  outline: none;
  border-radius: 10px;
  border: 2px solid #d9298a;
}

.submitBtn {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  border-radius: 10px;
  border: none;
  color: #f0f1f4;
  color: #080708;
  box-shadow: 0px 10px 18px 0 rgba(0,0,0,0.16);
  background-color: rgba(250, 208, 13, 1);
  letter-spacing: 1px;
  margin-top: 10px;
}

.submitBtn:hover {
  transform: translateY(-3px);
  box-shadow: 0px 10px 18px 0 rgba(0,0,0,0.2);
  cursor: pointer;
  transition: 0.4s;
}

.highlight {
  font-weight: 700;
}
