.mobileNav, .mobileNavCloseContainer {
  display: none;
}

.extendedNav {
  display: flex;
  justify-content: space-between;
  padding: 0px 50px;
  color: #080708;
}

.brand {
  display: flex;
  align-items: center;
}

.title {
  letter-spacing: 1px;
  margin-left: 15px;
  font-size: 25px;
  font-weight: 300;
}

.logoImg {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  margin: auto;
}

.links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  margin: 0px 30px;
  letter-spacing: 1px;
  height: 25px;
  text-decoration: none;
  color: #080708;
}

.noLink {
  color: #080708;
  text-decoration: none;
}

.noLink:hover {
  transform: translateY(-3px);
  color: #000;
  cursor: pointer;
  transition: 0.4s;
}

.currentLink {
  border-bottom: 3px solid #fad00d;
}

.link:hover {
  border-bottom: 3px solid #fad00d;
  transition-delay: 0.1s;
}

@media (max-width: 800px) {
  .extendedNav {
    display: none;
  }

  .mobileNavCloseContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    font-size: 28px;
    margin-top: 10px;
  }

  .mobileNav {
    display: block;
  }

  .mobileNavContainer {
    background-color: #757E95;
    background-color: #d9298a;
    opacity: 0.8;
    color: #fff;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-between;
    position: fixed;
  }

  .closeTag {
    display: flex;
    align-items: center;
    font-size: 22px;
    margin-left: 25px;
  }

  .links {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    margin-right: 25px;
  }

  .link {
    color: #fff;
    margin: 2px 0;
  }
}
