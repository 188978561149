.container {
  width: 390px;
  height: 100%;
  text-align: center;
  letter-spacing: 1px;
  box-shadow: 0px 10px 30px 0 rgba(0,0,0,0.16);
  margin: 0px 20px 30px 20px;
  color: #080708;
}

.imgContainer img {
  width: 100%;
  object-fit: scale-down;
}

.cardInfo {
  padding: 0 20px;
}

.bio {
  padding: 0 10px;
}

.link {
  color: #d9298a;
  text-decoration: underline;
}

.socialIcon {
  display: inline-block;
  background-color: #d9298a;
  color: #f0f1f4;
  padding: 5px;
  border-radius: 100%;
  font-size: 17px;
  margin: 0px 3px;
}

.socialIcon:hover {
  transform: translateY(-2px);
  cursor: pointer;
  transition: 0.4s;
}

@media (max-width: 900px) {
  .container {
    width: 300px;
    margin: 15px 0px;
    align-self: center;
  }
}
