.container {
  width: 450px;
  text-align: center;
  letter-spacing: 1px;
  box-shadow: 0px 10px 30px 0 rgba(0,0,0,0.16);
  margin-bottom: 30px;
  color: #080708;
  overflow-wrap: break-word;
  max-height: 400px;
}

.imgContainer {
  max-width: 450px;
  height: 300px;
  margin: auto;
  box-shadow: 0px 10px 18px 0 rgba(0,0,0,0.16);
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgContainer img {
  max-width: 450px;
  max-height: 300px;
  position: absolute;
  object-fit: scale-down;
}

.cardInfo {
  text-align: left;
  background-color: #dee2e6;
}

.postingInfo {
  display: flex;
  justify-content: space-between;
}

.postingInfo p, .title, .artist {
  margin: 0;
  margin-bottom: 10px;
  padding: 0 10px;
}

.title {
  padding-top: 5px;
}

.artist {
  font-weight: 600;
}

.location, .date {
  color: #6c757d;
}

.date {
  text-align: right;
}

@media (max-width: 900px) {
  .container {
    width: 315px;
  }

  .imgContainer {
    max-width: 315px;
    height: 210px;
  }

  .imgContainer img {
    max-width: 315px;
    max-height: 210px;
  }
}
