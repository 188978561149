.container {
  color: #080708;
  margin-top: 30px;
}

.title {
  font-size: 42px;
  font-weight: 300;
  letter-spacing: 2px;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 5px 0 20px 0;
  padding: 10px 80px;
}

.link {
  color: #d9298a;
}

.founders {
  padding: 0px 50px;
  display: flex;
  justify-content: center;
}

.featured {
  display: flex;
  justify-content: center;
}

@media (max-width: 900px) {
  .container {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  .title {
    font-size: 38px;
  }

  .subtitle {
    font-size: 20px;
    padding: 0 20px;
  }

  .founders, .featured {
    flex-direction: column;
  }
}
